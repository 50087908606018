import { useActionCallback } from "@easybiz/hook";
import { useFeedbackToast } from "@easybiz/component-shared";
import { OPERATION_GROUP_ORDER, ORDER_WRITE_SALES_RETURN } from "@easybiz/utils";
import { useIntl } from "react-intl";

function useOrderSalesReturn(callback) {
  const toast = useFeedbackToast();
  const intl = useIntl();

  return useActionCallback(
    ({ orderId, reportId, photos, creditNote, approval }) => {
      if (!creditNote?.lineTotal) {
        return intl.formatMessage({
          id: "please.enter.sales.return.amount",
          defaultMessage: "Please enter sales return amount",
        });
      }

      return [
        OPERATION_GROUP_ORDER,
        ORDER_WRITE_SALES_RETURN,
        {
          orderId,
          ...(photos && { photos }),
          ...(reportId && { reportId }),
          ...(creditNote && { creditNote }),
          ...(typeof approval === "boolean" && { approval })
        },
      ];
    },
    callback,
    (request, response) =>
      toast.success(
        response.pendingApproval
          ? intl.formatMessage({
              id: "sales.return.submitted.pending.admin.approval",
              defaultMessage: "Sales return submitted, pending admin approval",
            })
          : intl.formatMessage({
              id: "sales.return.success",
              defaultMessage: "Sales return success",
            })
      )
  );
}

export default useOrderSalesReturn;
