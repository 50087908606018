export default function getStorageUpload(folder, { doc, collection, getFirestore }, token, fixedRowId) {
  const { realmId, plan } = token || {};
  const [region] = `${realmId}`.includes("_") ? `${realmId}`.split("_") : ["sg"];
  const rawId =
    fixedRowId || (doc ? doc(collection(getFirestore(), "uploads")).id : getFirestore().collection("uploads").doc().id);
  const resourceId = `${plan || "s"}${folder}_${rawId}`;

  return {
    bucket: `easybiz-${region}`,
    resourceId,
    path: `${plan || "s"}/${folder}/${realmId}/${rawId}`,
  };
}
