import { AmountDisplay, UnitDisplay } from "@easybiz/component";
import { WRITE_OVERWRITE_PRICE, PERMISSION_LEVEL_BLOCKED } from "@easybiz/lib-shared-auth";
import { useAccessAction } from "@easybiz/component-shared";
import { Button, message, Popover } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import NumericUpdatePanel from "../NumericUpdatePanel";
import Title from "../Title";

export default ({ unit, unitPrice, onChange }) => {
  const [open, setVisible] = useState(false);
  const intl = useIntl();
  const [{ level }] = useAccessAction(WRITE_OVERWRITE_PRICE);
  const disabled = unitPrice > 0 && level === PERMISSION_LEVEL_BLOCKED;

  return (
    <Popover
      trigger={"click"}
      destroyTooltipOnHide
      placement={"right"}
      title={
        <Title>
          <FormattedMessage defaultMessage={"Unit Price"} />
        </Title>
      }
      open={disabled ? false : open}
      onOpenChange={setVisible}
      overlayStyle={{ width: 300 }}
      content={
        <NumericUpdatePanel
          open={open}
          isCurrency
          value={unitPrice}
          suffix={
            unit ? (
              <span>
                /<UnitDisplay unit={unit} />
              </span>
            ) : null
          }
          onDone={(unitPrice) => {
            if (typeof unitPrice !== "number") {
              return message.info(
                intl.formatMessage({
                  defaultMessage: "Please enter unit price",
                })
              );
            }

            setVisible(false);
            onChange(unitPrice);
          }}
        />
      }
    >
      <Button disabled={disabled}>
        <b>
          <AmountDisplay value={unitPrice} />
        </b>
      </Button>
    </Popover>
  );
};
