// Core resources
export const CORE_CUSTOMER = "customer";
export const CORE_ORDER = "order";
export const CORE_JOB = "job";
export const CORE_PAYMENT = "payment";
export const CORE_REFUND = "refund";
export const CORE_CREDIT_NOTE = "credit_note";
export const CORE_PRODUCT = "product";
export const CORE_WORKFLOW = "workflow";
export const CORE_PROCESS = "process";
export const CORE_STEP = "step";
export const CORE_EVENT = "event";

// Actions are fixed
export const ACTION_CREATE = "create";
export const ACTION_UPDATE = "update";
export const ACTION_CANCEL = "cancel";
export const ACTION_COMPLETE = "complete";
export const ACTION_FAIL = "fail";

// Standard actions
export const ACTION_ENTER_CUSTOMER_CONTACT = "enter_customer_contact";
export const ACTION_SELECT_CUSTOMER_ACCOUNT = "select_customer_account";
export const ACTION_FIND_CUSTOMER_ADDRESS = "find_customer_address";
export const ACTION_SELECT_RECEIVING_DATE = "select_receiving_date";
export const ACTION_SELECT_DELIVERY_DATE = "select_delivery_date";
export const ACTION_SELECT_BOOKING_DATES = "select_booking_dates";
export const ACTION_EDIT_ORDER_ITEMS = "edit_order_items";
export const ACTION_REVIEW_PACKAGES = "review_packages";
export const ACTION_SELECT_ITEMS_TO_PROCESS = "select_items_to_process";
export const ACTION_SELECT_STORAGE_LOCATION = "select_storage_location";
export const ACTION_SCAN_PICKUP_BAGS = "scan_pickup_bags";
export const ACTION_SCAN_DELIVERY_PACKAGES = "scan_delivery_packages";
export const ACTION_COLLECT_PAYMENT = "collect_payment";
export const ACTION_SIGNATURE = "signature";
export const ACTION_TAKE_PHOTO = "take_photo";
export const ACTION_SCAN_QR_CODE = "scan_qr_code";
export const ACTION_ENTER_ORDER_NUMBER = "enter_order_number";
export const ACTION_ENTER_TRACKING_NUMBER = "enter_tracking_number";
export const ACTION_ENTER_ORDER_TOTAL_AMOUNT = "enter_order_total_amount";
export const ACTION_ENTER_ORDER_TOTAL_QTY = "enter_order_total_qty";
export const ACTION_ENTER_NOTE = "enter_note";
export const ACTION_CUSTOM_DATA_ENTRY = "custom_data_entry";
export const ACTION_STORAGE_IN = "storage_in";
export const ACTION_STORAGE_OUT = "storage_out";
export const ACTION_ENTER_LINE_ITEM_QTY = "enter_line_item_qty";
export const ACTION_ENTER_LINE_ITEM_PCS = "enter_line_item_pcs";
export const ACTION_ENTER_LINE_ITEM_UNIT_PRICE = "enter_line_item_unit_price";
export const ACTION_SELECT_LINE_ITEM_DATE = "select_line_item_date";
export const ACTION_ENTER_PACKAGE_QTY = "enter_package_qty";

// Data fields
export const DATA_FIELD_TYPE_TEXT = "d_t";
export const DATA_FIELD_TYPE_LONG_TEXT = "d_lt";
export const DATA_FIELD_TYPE_NUMBER = "d_n";
export const DATA_FIELD_TYPE_AMOUNT = "d_a";
export const DATA_FIELD_TYPE_DATE = "d_d";
export const DATA_FIELD_TYPE_PHONE_NUMBER = "d_pn";
export const DATA_FIELD_TYPE_EMAIL = "d_e";
export const DATA_FIELD_TYPE_SINGLE_SELECT = "d_ss";
export const DATA_FIELD_TYPE_MULTI_SELECT = "d_ms";
export const DATA_FIELD_TYPE_IMAGE = "d_i";
export const DATA_FIELD_TYPE_QR_CODE = "d_qr";

// QR code types
export const QR_CODE_TYPE_ORDER = "O";
export const QR_CODE_TYPE_ITEM = "I";
export const QR_CODE_TYPE_PACK = "P";
export const QR_CODE_TYPE_PICKUP_BAG = "BAG";
export const QR_CODE_TYPE_STORAGE = "STORE";
export const QR_CODE_TYPE_WORKFLOW = "W";
export const QR_CODE_TYPE_CLOUD_APP = "CA";
export const QR_CODE_DIVIDER = "::";

// Core Resources Types
export const CORE_RESOURCES_SALES_ORDER = "so";
export const CORE_RESOURCES_SALES_ORDER_LINE_ITEM = "soli";
export const CORE_RESOURCES_WORK_ORDER = "wo";
export const CORE_RESOURCES_LOGISTIC_JOB = "lj";

// Procedure types
export const PROCEDURE_TYPE_ORDER_MANAGEMENT = "o";
export const PROCEDURE_TYPE_JOB_MANAGEMENT = "j";
export const PROCEDURE_TYPE_LINE_ITEM_MANAGEMENT = "l";
export const PROCEDURE_TYPE_DELIVERY_PACKAGE_MANAGEMENT = "d";
export const PROCEDURE_TYPE_SCAN_CHECKPOINT = "s";
export const PROCEDURE_TYPE_PHOTO_CAPTURE = "p";
export const PROCEDURE_TYPE_HANDOVER = "h";

export const AMOUNT_ENTRY_METHOD_CENTS_FIRST = "cents_first";
export const AMOUNT_ENTRY_METHOD_DOLLARS_FIRST = "dollars_first";
export const AMOUNT_ENTRY_METHOD_DOLLARS_ONLY = "dollars_only";

export const ACTION_PROCESS_METHOD_REQUIRED_STEP = "required_step";
export const ACTION_PROCESS_METHOD_OPTIONAL_STEP = "optional_step";
export const ACTION_PROCESS_METHOD_QUICK_REFERENCE = "quick_reference";

// Industry
export const INDUSTRY_ID_LAUNDRY = "laundry";

// Fulfillment
export const FULFILLMENT_TYPE_DELIVERY = "delivery";
export const FULFILLMENT_TYPE_IN_STORE_PICKUP = " in_store_pickup";

// Printer types
export const PRINTER_TYPE_RECEIPT = "receipt";
export const PRINTER_TYPE_ITEM_LABEL = "label";
export const PRINTER_TYPE_PACKAGE_LABEL = "sticker";

// Date types
export const DATE_TYPE_CREATE = "create";
export const DATE_TYPE_FULFILLMENT = "fulfillment";
