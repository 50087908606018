import { EnvironmentFilled, LoadingOutlined } from "@ant-design/icons";
import { useUserLocationName } from "@easybiz/component-shared";
import { fetchGooglePlaceAddresses, useGooglePlaces } from "@easybiz/component-web-business";
import { useLocalAddresses } from "@easybiz/web-firebase";
import { AutoComplete, message } from "antd";
import { useState } from "react";
import { useIntl } from "react-intl";

export default function ({ onSelect, disabled }) {
  const cityName = useUserLocationName();
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const { value, places, setValue } = useGooglePlaces();

  const localAddresses = useLocalAddresses(cityName, value);

  const onFetchDetal = async (place) => {
    try {
      setLoading(true);

      const addresses = await fetchGooglePlaceAddresses(place.place_id, true);

      if (addresses.length > 0) {
        onSelect(addresses[0]);
      } else {
        message.info(
          intl.formatMessage({
            defaultMessage: "Please select address with postal code",
          })
        );
      }
    } catch (err) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AutoComplete
      value={value}
      suffixIcon={loading ? <LoadingOutlined /> : null}
      searchValue={value}
      autoFocus
      disabled={disabled}
      showSearch
      className="full-width"
      onSelect={(addressId) => {
        if (addressId) {
          let place = localAddresses && localAddresses.find((place) => place.place_id === addressId);

          if (place) {
            onSelect(place);
          } else {
            place = places && places.find((place) => place.place_id === addressId);
            if (place) {
              onFetchDetal(place);
            }
          }
        } else {
          onSelect(null);
        }
      }}
      onSearch={(search) => setValue(search)}
      placeholder={intl.formatMessage({
        defaultMessage: "Type & select place",
      })}
      allowClear
    >
      {localAddresses &&
        localAddresses.map((address) => (
          <AutoComplete.Option style={{ padding: 12 }} key={address.place_id} value={address.place_id}>
            <EnvironmentFilled /> {address.formatted}
          </AutoComplete.Option>
        ))}
      {places &&
        places.map((place) => (
          <AutoComplete.Option style={{ padding: 12 }} key={place.place_id} value={place.place_id}>
            <EnvironmentFilled /> {place.description}
          </AutoComplete.Option>
        ))}
    </AutoComplete>
  );
}
