import { ROUNDING_DIRECTION_DOWN, ROUNDING_DIRECTION_NEAREST, ROUNDING_DIRECTION_UP } from "./order.js";

export default function coinRounding(amount, rounding, returnRoundingAmount = false) {
  // Extract relevant options with defaults
  const { coin = true, precision = 2, increment = 5, direction = ROUNDING_DIRECTION_NEAREST } = rounding || {};

  // Helper for safely flooring a floating number
  // by adding a small epsilon so 101.9999999 becomes 102
  function safeFloor(num) {
    // You might experiment with a different epsilon magnitude, e.g. 1e-10
    return Math.floor(num + 1e-12);
  }

  const factor = Math.pow(10, precision);
  let coinRoundedAmount = amount;

  if (coin && Number.isInteger(increment) && increment > 0) {
    // Example: increment = 10, precision = 2 => coinFactor = 0.1
    const coinFactor = increment / factor;

    switch (direction) {
      case ROUNDING_DIRECTION_NEAREST:
        coinRoundedAmount = Math.round(amount / coinFactor) * coinFactor;
        break;

      case ROUNDING_DIRECTION_UP:
        coinRoundedAmount = Math.ceil(amount / coinFactor) * coinFactor;
        break;

      case ROUNDING_DIRECTION_DOWN:
        // The line that triggers floating-point issues:
        //   Math.floor(10.2 / 0.1) => sometimes 101, not 102
        // Instead, use safeFloor:
        coinRoundedAmount = safeFloor(amount / coinFactor) * coinFactor;
        break;

      default:
        // No coin rounding direction, do nothing special
        break;
    }
  }

  // Convert final coinRoundedAmount to a numeric with the desired precision
  const roundedAmountNumeric = parseFloat(coinRoundedAmount.toFixed(precision));
  // Calculate how much we rounded by
  const roundingAmountNumeric = parseFloat((roundedAmountNumeric - amount).toFixed(precision));

  if (returnRoundingAmount) {
    return {
      roundedAmount: roundedAmountNumeric,
      roundingAmount: roundingAmountNumeric,
    };
  }

  return roundedAmountNumeric;
}
