import {
  NODE_TYPE_MENTION,
  PLACEHOLDER_COMPANY_LOGO,
  PLACEHOLDER_COMPANY_REGISTRATION_NUMBER,
  PLACEHOLDER_DATA_SET_COMPANY,
  PLACEHOLDER_DATA_SET_CREDIT,
  PLACEHOLDER_DATA_SET_CUSTOMER,
  PLACEHOLDER_DATA_SET_JOB,
  PLACEHOLDER_DATA_SET_MEMBER,
  PLACEHOLDER_DATA_SET_ORDER,
  PLACEHOLDER_DATA_SET_OTP,
  PLACEHOLDER_DATA_SET_SALES_CHANNEL,
  PLACEHOLDER_DATA_SET_TRANSACTION,
  PLACEHOLDER_HANDOVER_ORDERS,
  PLACEHOLDER_INVOICE_ORDERS,
  PLACEHOLDER_ORDER_ADJUSTMENT_ITEMS,
  PLACEHOLDER_ORDER_AMOUNT_DUE,
  PLACEHOLDER_ORDER_AMOUNT_DUE_RAW,
  PLACEHOLDER_ORDER_ID,
  PLACEHOLDER_ORDER_LINE_ITEMS,
  PLACEHOLDER_ORDER_NO,
  PLACEHOLDER_ORDER_PAYMENTS,
  PLACEHOLDER_ORDER_PAYNOW_IMAGE_URL,
  PLACEHOLDER_ORDER_PAYNOW_QR_CONTENT,
  PLACEHOLDER_SIGNATURE,
} from "./constants/content.js";
import {
  COMPONENT_ADJUSTMENT_ITEMS,
  COMPONENT_CUSTOMER_SIGNATURE,
  COMPONENT_DATA_TABLE,
  COMPONENT_HANDOVER_ORDER_LIST,
  COMPONENT_LINE_ITEMS,
  COMPONENT_LOGO,
  COMPONENT_ORDER_PAYMENTS,
  COMPONENT_ORDER_QR_CODE,
  COMPONENT_PAYNOW_MANUAL_QR,
  COMPONENT_PAYNOW_STRIPE_QR,
  LINK_TYPE_PAGE,
} from "./constants/template.js";
import { WHATSAPP_HEADER_FORMAT_DOCUMENT, WHATSAPP_HEADER_FORMAT_IMAGE } from "./constants/whatsapp.js";
import retrieveUrlParams from "./retrieveUrlParams.js";

function addPlaceholder(placeholders, key) {
  if (!placeholders.includes(key)) {
    placeholders.push(key);
  }
}

function retrievePlaceholders(data, placeholders, params, parent, offsetDays) {
  for (const key in data) {
    if (["type", "subType"].includes(key)) {
      switch (data[key]) {
        case NODE_TYPE_MENTION:
          // TipTap mention
          if (data.attrs?.id) {
            addPlaceholder(placeholders, data.attrs.id);

            if (offsetDays) {
              if (!params.offsetDays) {
                params.offsetDays = {};
              }
              if (!params.offsetDays[data.attrs.id]) {
                params.offsetDays[data.attrs.id] = [];
              }

              if (!params.offsetDays[data.attrs.id].includes(offsetDays)) {
                params.offsetDays[data.attrs.id].push(offsetDays);
              }
            }
          }
          break;
        case LINK_TYPE_PAGE:
          // Page link
          if (data.pageId) {
            retrieveUrlParams(data, placeholders);
          }
          break;
        case COMPONENT_LINE_ITEMS:
          addPlaceholder(placeholders, PLACEHOLDER_ORDER_LINE_ITEMS);
          if (parent?.content) {
            params[PLACEHOLDER_ORDER_LINE_ITEMS] = parent?.content;
          }
          break;
        case COMPONENT_ADJUSTMENT_ITEMS:
          addPlaceholder(placeholders, PLACEHOLDER_ORDER_ADJUSTMENT_ITEMS);
          if (parent?.content) {
            params[PLACEHOLDER_ORDER_ADJUSTMENT_ITEMS] = parent?.content;
          }
          break;
        case COMPONENT_ORDER_PAYMENTS:
          addPlaceholder(placeholders, PLACEHOLDER_ORDER_PAYMENTS);
          if (parent?.content) {
            params[PLACEHOLDER_ORDER_PAYMENTS] = parent.content;
          }
          break;
        case COMPONENT_HANDOVER_ORDER_LIST:
          addPlaceholder(placeholders, PLACEHOLDER_HANDOVER_ORDERS);
          if (parent?.content) {
            params[PLACEHOLDER_HANDOVER_ORDERS] = parent.content;
          }
          break;
        case COMPONENT_DATA_TABLE:
          addPlaceholder(placeholders, PLACEHOLDER_INVOICE_ORDERS);
          break;
        case COMPONENT_ORDER_QR_CODE:
          addPlaceholder(placeholders, PLACEHOLDER_ORDER_ID);
          break;
        case COMPONENT_PAYNOW_STRIPE_QR:
          addPlaceholder(placeholders, PLACEHOLDER_ORDER_PAYNOW_IMAGE_URL);
          break;
        case COMPONENT_PAYNOW_MANUAL_QR:
          addPlaceholder(placeholders, PLACEHOLDER_ORDER_PAYNOW_QR_CONTENT);
          addPlaceholder(placeholders, PLACEHOLDER_COMPANY_REGISTRATION_NUMBER);
          addPlaceholder(placeholders, PLACEHOLDER_ORDER_AMOUNT_DUE);
          addPlaceholder(placeholders, PLACEHOLDER_ORDER_AMOUNT_DUE_RAW);
          addPlaceholder(placeholders, PLACEHOLDER_ORDER_NO);
          break;
        case COMPONENT_LOGO:
        case "receipt_logo":
          addPlaceholder(placeholders, PLACEHOLDER_COMPANY_LOGO);
          break;
        case "receipt_signature":
        case COMPONENT_CUSTOMER_SIGNATURE:
          addPlaceholder(placeholders, PLACEHOLDER_SIGNATURE);
          break;
      }
    } else if (
      key === "name" &&
      data[key] === "header" &&
      [WHATSAPP_HEADER_FORMAT_IMAGE, WHATSAPP_HEADER_FORMAT_DOCUMENT].includes(data.content?.format)
    ) {
      // WhatsApp header
      if (data.content?.format) {
        addPlaceholder(placeholders, data.content?.format);
      }
    } else if (key === "condition" && data[key]?.placeholder) {
      addPlaceholder(placeholders, data[key]?.placeholder);
    }

    if (data[key] && typeof data[key] === "object") {
      if (Array.isArray(data[key])) {
        data[key].forEach((item) => {
          if (item && typeof item === "object") {
            retrievePlaceholders(item, placeholders, params, data, offsetDays);
          }
        });
      } else {
        retrievePlaceholders(data[key], placeholders, params, data, offsetDays || data[key]?.content?.offsetDays);
      }
    }
  }
}

export default function retrieveDataSetsPlaceholders(data, initPlaceholders) {
  const dataSets = [];
  const params = {};
  const placeholders = Array.isArray(initPlaceholders) ? initPlaceholders : [];

  if (data) {
    retrievePlaceholders(data, placeholders, params);
  }

  for (const placeholderId of placeholders) {
    switch (placeholderId) {
      // TODO: Remove this change to use "company_brand_name";
      case "brand_name":
      case "legal_name":
        if (!dataSets.includes(PLACEHOLDER_DATA_SET_COMPANY)) {
          dataSets.push(PLACEHOLDER_DATA_SET_COMPANY);
        }
        break;
      default: {
        const dataSet = [
          PLACEHOLDER_DATA_SET_COMPANY,
          PLACEHOLDER_DATA_SET_CUSTOMER,
          PLACEHOLDER_DATA_SET_SALES_CHANNEL,
          PLACEHOLDER_DATA_SET_ORDER,
          PLACEHOLDER_DATA_SET_JOB,
          PLACEHOLDER_DATA_SET_TRANSACTION,
          PLACEHOLDER_DATA_SET_CREDIT,
          PLACEHOLDER_DATA_SET_MEMBER,
          PLACEHOLDER_DATA_SET_OTP,
        ].find((dataSet) => `${placeholderId}`.startsWith(`${dataSet}_`));

        if (dataSet && !dataSets.includes(dataSet)) {
          dataSets.push(dataSet);
        }
      }
    }
  }

  return [dataSets, placeholders, params];
}
