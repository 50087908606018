import calculateUnitIndex from "./calculateUnitIndex.js";
import {
  PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORIES,
  PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_CODES,
  PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_NAMES,
  PLACEHOLDER_ORDER_LINE_ITEM_ADD_ON_CODES,
  PLACEHOLDER_ORDER_LINE_ITEM_ADD_ON_NAMES,
  PLACEHOLDER_ORDER_LINE_ITEM_ADD_ONS,
  PLACEHOLDER_ORDER_LINE_ITEM_COMPLETION_DATE,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CODE,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CONTENT,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NOTE,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAILS,
  PLACEHOLDER_ORDER_LINE_ITEM_ID,
  PLACEHOLDER_ORDER_LINE_ITEM_INDEXES,
  PLACEHOLDER_ORDER_LINE_ITEM_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_NOTE,
  PLACEHOLDER_ORDER_LINE_ITEM_PCS_INDEX,
  PLACEHOLDER_ORDER_LINE_ITEM_PHOTO,
  PLACEHOLDER_ORDER_LINE_ITEM_QTY,
  PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_CODE,
  PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_SET_PCS_INDEX,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_CODE,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_DISCOUNT,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NOTE,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_PRICE,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTIONS,
  PLACEHOLDER_ORDER_LINE_ITEM_TOTAL,
  PLACEHOLDER_ORDER_LINE_ITEM_TOTAL_PCS_PER_SET,
  PLACEHOLDER_ORDER_LINE_ITEM_UNIT,
  PLACEHOLDER_ORDER_LINE_ITEM_UNIT_INDEX,
  PLACEHOLDER_ORDER_LINE_ITEM_UNIT_PRICE,
  PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_DETAIL,
  PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_SUB_SERVICE_OPTION,
} from "./constants/content.js";
import formatCustomDataField from "./formatCustomDataField.js";
import formatDate from "./formatDate.js";
import formatNumber from "./formatNumber.js";
import formatPhotoUrl from "./formatPhotoUrl.js";
import formatPrice from "./formatPrice.js";

export default function formatTemplateLineItem(
  item,
  { items, currency },
  realmId,
  dateFormat,
  currencyDisplay,
  placeholders
) {
  const {
    labelId,
    labelIndex,
    lineTotal,
    option,
    optionCode,
    product,
    productId,
    subType,
    qty,
    unit,
    unitPrice,
    photoId,
    measure,
    subOptions,
    selectSteps,
    accessories,
    addons,
    addonQty,
    note,
    notes,
    unitQty,
    unitLabel,
    pcs,
    date,
    labelIndexes,
  } = item;
  const showPrice = placeholders.includes(PLACEHOLDER_ORDER_LINE_ITEM_TOTAL);

  const lineItem = {
    [PLACEHOLDER_ORDER_LINE_ITEM_ID]: productId || null,
  };

  placeholders.forEach((placeholder) => {
    switch (placeholder) {
      case PLACEHOLDER_ORDER_LINE_ITEM_NAME:
        lineItem[placeholder] = `${product || ""}${subType ? ` - ${subType}` : ""}${measure ? ` (${measure})` : ""}${
          unitQty && unitLabel ? ` (${unitQty} ${unitLabel})` : ""
        }${Number.isInteger(pcs) ? ` (Total ${pcs} pcs)` : ""}`;
        break;
      case PLACEHOLDER_ORDER_LINE_ITEM_TOTAL:
        lineItem[placeholder] = formatNumber(lineTotal, currency, true, currencyDisplay);
        break;
      case PLACEHOLDER_ORDER_LINE_ITEM_QTY:
        lineItem[placeholder] = qty;
        break;
      case PLACEHOLDER_ORDER_LINE_ITEM_UNIT:
        lineItem[placeholder] = unit;
        break;
      case PLACEHOLDER_ORDER_LINE_ITEM_UNIT_PRICE:
        lineItem[placeholder] = formatNumber(unitPrice, currency, true, currencyDisplay);
        break;
      case PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_CODE:
        if (optionCode) {
          lineItem[placeholder] = optionCode;
        }
        break;
      case PLACEHOLDER_ORDER_LINE_ITEM_PHOTO:
        if (photoId) {
          lineItem[placeholder] = {
            url: formatPhotoUrl(photoId, realmId),
          };
        }
        break;
      case PLACEHOLDER_ORDER_LINE_ITEM_COMPLETION_DATE:
        if (date) {
          lineItem[placeholder] = formatDate(date, dateFormat);
        }
        break;
      case PLACEHOLDER_ORDER_LINE_ITEM_INDEXES:
        if (Array.isArray(labelIndexes)) {
          lineItem[placeholder] = labelIndexes.map((index) => `#${index}`).join("/");
        }
        break;
      case PLACEHOLDER_ORDER_LINE_ITEM_NOTE: {
        // Notes
        const itemNote = (Array.isArray(notes) && labelIndex ? notes[labelIndexes?.indexOf(labelIndex)] : null) ?? note;
        const itemNotes = itemNote ? [itemNote] : [];

        if (Array.isArray(selectSteps)) {
          selectSteps.forEach(({ code, title, value }) => {
            if (Array.isArray(value)) {
              let noteMap = {},
                codeMap = {};

              value.forEach(({ note, code }) => {
                if (note && code) {
                  const noteKey = `${note}`.trim().toLowerCase();
                  noteMap[noteKey] = note;
                  codeMap[code] = noteKey;
                }
              });

              let notes = Object.values(codeMap);
              let codes = Object.keys(codeMap);
              // Prefilled notes
              if (notes.length > 0) {
                notes = notes.filter((note, index) => notes.indexOf(note) === index);
                const stepNotes = notes.map(
                  (noteKey) => `${codes.filter((code) => codeMap[code] === noteKey).join("/")}: ${noteMap[noteKey]}`
                );
                itemNotes.push(`** Attention on ${title || code}`);
                stepNotes.forEach((note) => itemNotes.push(note));
              }
            }
          });
        }

        if (itemNotes.some(Boolean)) {
          lineItem[placeholder] = itemNotes.filter(Boolean).join("\n");
        }
        break;
      }
      case PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_NAME:
        if (option) {
          lineItem[placeholder] = option;
        }
        break;
      case PLACEHOLDER_ORDER_LINE_ITEM_ADD_ON_NAMES:
        if (addons?.length > 0) {
          lineItem[placeholder] = addons
            .map(({ title }) => title)
            .filter(Boolean)
            .filter((title, index, array) => array.indexOf(title) === index)
            .join("/");
        }
        break;
      case PLACEHOLDER_ORDER_LINE_ITEM_ADD_ON_CODES:
        if (addons?.length > 0) {
          lineItem[placeholder] = addons
            .map(({ code }) => code)
            .filter(Boolean)
            .filter((code, index, array) => array.indexOf(code) === index)
            .join("/");
        }
        break;
      case PLACEHOLDER_ORDER_LINE_ITEM_ADD_ONS:
        if (addons?.length > 0) {
          const displayAddons = [];

          addons.forEach(({ title, code, surcharge }) => {
            const price = typeof surcharge === "number" ? surcharge : 0;
            const displayTitle = title || code;
            const index = displayAddons.findIndex((other) => other.title === displayTitle && other.price === price);

            if (index >= 0) {
              displayAddons[index].qty++;
            } else {
              displayAddons.push({
                title: displayTitle,
                qty: typeof addonQty === "number" ? addonQty : 1,
                ...(showPrice && {
                  price,
                }),
              });
            }
          });

          lineItem[placeholder] = displayAddons
            .sort((a, b) => formatPrice(b.qty * b.price) - formatPrice(a.qty * a.price))
            .map(({ title, qty, price }) => `Add-on: ${title} x ${qty} (${formatPrice(price * qty)})`)
            .join("\n");
        }
        break;
      case PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_NAMES:
        if (accessories?.length > 0) {
          lineItem[placeholder] = accessories
            .map(({ title }) => title)
            .filter(Boolean)
            .filter((title, index, array) => array.indexOf(title) === index)
            .join("/");
        }
        break;
      case PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_CODES:
        if (accessories?.length > 0) {
          lineItem[placeholder] = accessories
            .map(({ code }) => code)
            .filter(Boolean)
            .filter((code, index, array) => array.indexOf(code) === index)
            .join("/");
        }
        break;
      case PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORIES:
        if (accessories?.length > 0) {
          lineItem[placeholder] = `Accessory: ${accessories.map(({ title, qty }) => `${title} x ${qty}`).join("; ")}`;
        }
        break;
      default: {
        const value = formatCustomDataField(placeholder, item, { dateFormat, currency, currencyDisplay });
        if (value) {
          lineItem[placeholder] = value;
        }
      }
    }
  });

  // Item label
  if (labelIndex) {
    lineItem[PLACEHOLDER_ORDER_LINE_ITEM_ID] = labelId;
    lineItem[PLACEHOLDER_ORDER_LINE_ITEM_PCS_INDEX] = labelIndex; // Important, label rendering using this index to determine which the line item needs to generate label

    const { unitIndex, unitTotalPcs, unitPcsIndex } = calculateUnitIndex(items, labelIndex);

    lineItem[PLACEHOLDER_ORDER_LINE_ITEM_UNIT_INDEX] = unitIndex;
    lineItem[PLACEHOLDER_ORDER_LINE_ITEM_TOTAL_PCS_PER_SET] = unitTotalPcs;
    lineItem[PLACEHOLDER_ORDER_LINE_ITEM_SET_PCS_INDEX] = unitPcsIndex;
  }

  if (
    subOptions?.length > 0 &&
    placeholders.some((placeholder) => `${placeholder}`.includes(PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_SUB_SERVICE_OPTION))
  ) {
    lineItem[PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTIONS] = subOptions.map(
      ({ title, code, price, discountAmount, note }) => {
        let subOption = {};

        placeholders.forEach((placeholder) => {
          switch (placeholder) {
            case PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NAME:
              subOption[placeholder] = title;
              break;
            case PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_CODE:
              if (code) {
                subOption[placeholder] = code;
              }
              break;
            case PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_PRICE:
              if (typeof price === "number") {
                subOption[placeholder] = formatNumber(price, currency, false, currencyDisplay);
              }
              break;
            case PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_DISCOUNT:
              if (typeof discountAmount === "number") {
                subOption[placeholder] = formatNumber(discountAmount, currency, false, currencyDisplay);
              }
              break;
            case PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NOTE:
              if (note) {
                subOption[placeholder] = note;
              }
              break;
          }
        });

        return subOption;
      }
    );
  }

  // Select steps
  if (
    selectSteps?.length > 0 &&
    placeholders.some((placeholder) => `${placeholder}`.includes(PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_DETAIL))
  ) {
    lineItem[PLACEHOLDER_ORDER_LINE_ITEM_DETAILS] = (selectSteps || []).map(({ title, code, value, note }) => {
      let itemDetail = {};

      placeholders.forEach((placeholder) => {
        switch (placeholder) {
          case PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NAME:
            if (title) {
              itemDetail[placeholder] = title;
            }
            break;
          case PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CODE:
            if (code) {
              itemDetail[placeholder] = code;
            }
            break;
          case PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CONTENT:
            if (typeof value === "string") {
              itemDetail[placeholder] = value;
            } else if (Array.isArray(value)) {
              itemDetail[placeholder] = value.map(({ title, code }) => `${title || code}`).join(", ");
            }
            break;
          case PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NOTE:
            if (note) {
              itemDetail[placeholder] = note;
            }
            break;
        }
      });

      return itemDetail;
    });
  }

  return lineItem;
}
