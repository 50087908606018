export default function formatNumber(value, currency, accounting, currencyDisplay = "narrowSymbol") {
  if (typeof value !== "number") return "";

  const options = currency
    ? {
        style: "currency",
        currency,
        ...(["code", "symbol", "narrowSymbol", "name"].includes(currencyDisplay) && {
          currencyDisplay,
        }),
        ...(accounting && { currencySign: "accounting" }),
      }
    : {
        minimumFractionDigits: Number.isInteger(accounting) ? accounting : 2,
      };

  const formatter = new Intl.NumberFormat("en-US-POSIX", options);

  if (currencyDisplay === false || currencyDisplay === "none") {
    // Exclude the currency symbol
    if (typeof formatter.formatToParts === "function") {
      const parts = formatter.formatToParts(value);

      const formattedNumber = parts
        .filter((part) => part.type !== "currency")
        .map((part) => part.value)
        .join("");

      return formattedNumber.replace(/\u00A0/g, " ");
    } else {
      // Fallback for environments (like React Native) that lack formatToParts.
      // We'll create a decimal formatter using similar fraction–digit options.
      const resolved = formatter.resolvedOptions();
      const minFractionDigits = resolved.minimumFractionDigits || 2;
      const numberFormatter = new Intl.NumberFormat("en-US-POSIX", {
        style: "decimal",
        minimumFractionDigits: minFractionDigits,
        maximumFractionDigits: minFractionDigits,
      });

      // Format the absolute value (so that we can later decide how to show negatives).
      const formatted = numberFormatter.format(Math.abs(value)).replace(/\u00A0/g, " ");

      // If accounting formatting is desired and the number is negative, wrap it in parentheses.
      if (accounting && value < 0) {
        return `(${formatted})`;
      } else if (value < 0) {
        // Otherwise, simply prepend a minus sign.
        return "-" + formatted;
      }

      return formatted;
    }
  } else {
    // Include the currency symbol as usual
    return formatter.format(value).replace(/\u00A0/g, " ");
  }
}
