import {
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAILS,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTIONS,
  PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_DETAIL,
  PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_SUB_SERVICE_OPTION,
} from "./constants/content.js";
import formatPlaceholderName from "./formatPlaceholderName.js";
import renderPlaceholder from "./renderPlaceholder.js";
import formatFixedLengthTextLines from "./renderReceiptLines/formatFixedLengthTextLines.js";

function filterRepeatContent(dataArray, nameKey, filter) {
  return (dataArray || []).filter(
    (detail) =>
      !filter ||
      `${filter}`.split(",").some((name) => `${detail[nameKey]}`.toLowerCase().includes(`${name}`.trim().toLowerCase()))
  );
}

function repeatParagraphContent(content, firstIndex, dataSetKey, dataArray, fileNameKey, filter) {
  const lastIndex = content.findIndex(
    ({ type, attrs }, index) =>
      type === "mention" &&
      `${attrs?.id}`.startsWith(dataSetKey) &&
      !content.some(
        (other, otherIndex) =>
          other.type === "mention" && `${other.attrs?.id}`.startsWith(dataSetKey) && otherIndex > index
      )
  );

  return [
    ...content.slice(0, firstIndex),
    ...filterRepeatContent(dataArray, fileNameKey, filter).flatMap((contentRepeatParams, index) => {
      return [
        ...content.slice(firstIndex, lastIndex + 1).map((item) => ({
          ...item,
          contentRepeatParams,
        })),
        ...(index < dataArray.length - 1 ? [{ type: "text", text: " " }] : []), // Add space between repeated contents
      ];
    }),
    ...content.slice(lastIndex + 1),
  ];
}

export default function formatLabelTextLines(json, attrs, renderParams, characterPerLine, intl) {
  let lines = [];

  json?.content?.forEach(({ content, type }) => {
    if (type === "paragraph") {
      let paragraphs = [];

      if (Array.isArray(content)) {
        const firstDetailIndex = renderParams
          ? content.findIndex(
              ({ type, attrs }) =>
                type === "mention" && `${attrs?.id}`.startsWith(PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_DETAIL)
            )
          : -1;
        const firstServiceIndex = renderParams
          ? content.some(
              ({ type, attrs }) =>
                type === "mention" && `${attrs?.id}`.startsWith(PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_SUB_SERVICE_OPTION)
            )
          : -1;

        let repeats;

        if (
          firstDetailIndex >= 0 &&
          !content.some(
            ({ type, attrs }) =>
              type === "mention" && !`${attrs?.id}`.startsWith(PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_DETAIL)
          )
        ) {
          // Paragraph only contains item detail, repeat paragraphs for each item detail
          repeats = filterRepeatContent(
            renderParams[PLACEHOLDER_ORDER_LINE_ITEM_DETAILS],
            PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NAME,
            json.stepFilters
          );
        } else if (
          firstServiceIndex >= 0 &&
          !content.some(
            ({ type, attrs }) =>
              type === "mention" && !`${attrs?.id}`.startsWith(PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_SUB_SERVICE_OPTION)
          )
        ) {
          // Paragraph only contains item sub service options, repeat paragraphs for each item sub service option
          repeats = filterRepeatContent(
            renderParams[PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTIONS],
            PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NAME,
            json.subOptionFilters
          );
        }

        paragraphs = (repeats || [null]).map((repeatParams) => {
          let repeatContent;

          if (firstDetailIndex >= 0 && !repeats) {
            // Repeat within the same paragraph
            repeatContent = repeatParagraphContent(
              content,
              firstDetailIndex,
              PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_DETAIL,
              renderParams[PLACEHOLDER_ORDER_LINE_ITEM_DETAILS],
              PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NAME,
              json.stepFilters
            );
          } else if (firstServiceIndex >= 0 && !repeats) {
            // Repeat within the same paragraph
            repeatContent = repeatParagraphContent(
              content,
              firstServiceIndex,
              PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_SUB_SERVICE_OPTION,
              renderParams[PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTIONS],
              PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NAME,
              json.subOptionFilters
            );
          }

          return (repeatContent || content)
            .map(({ attrs, type, text, contentRepeatParams }) => {
              switch (type) {
                case "text":
                  return text;
                case "mention":
                  return renderParams
                    ? renderPlaceholder(
                        contentRepeatParams || repeatParams
                          ? { ...renderParams, ...(contentRepeatParams || repeatParams) }
                          : renderParams,
                        attrs?.id,
                        json
                      )
                    : formatPlaceholderName(attrs?.id, intl, null, true) || attrs?.id;
              }
            })
            .filter(Boolean)
            .join("");
        });
      }

      if (
        (paragraphs.length > 0 || !renderParams) &&
        (!Number.isInteger(attrs?.maxLines) || lines.length < attrs.maxLines)
      ) {
        const splitParagraphs = paragraphs.flatMap((paragraph) =>
          formatFixedLengthTextLines(paragraph, characterPerLine)
        );

        lines.push(...(splitParagraphs.length > 0 ? splitParagraphs : [""]));
      }
    }
  });

  return lines;
}
