import { CalendarOutlined, CarOutlined } from "@ant-design/icons";
import { DateDisplay } from "@easybiz/component";
import { useCheckoutCustomer, useCheckoutParamsUpdate, useCheckoutService, useClientDate, useClientState, useRealmToDisplayName, useUserBusinessCode } from "@easybiz/component-shared";
import { BUSINESS_SECTOR_LAUNDRY, JOB_TYPE_DELIVERY } from "@easybiz/utils";
import { ScheduleDialog } from "@easybiz/web-admin";
import { Badge, Button } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function CheckoutCollectionScheduleButton({
  address,
  returnSchedule,
  collectionPlace,
  selected,
  onSelect,
}) {
  const [deliverySchedule, setDeliverySchedule] = useState(false);
  const updateCheckout = useCheckoutParamsUpdate();
  const intl = useIntl();
  const date = useClientDate();
  const { collectionOptions } = useClientState();
  const customer = useCheckoutCustomer();
  const businessCode = useUserBusinessCode();
  const service = useCheckoutService();
  const hasStorePickupOption = service?.storePickup ? true : (service?.sector === BUSINESS_SECTOR_LAUNDRY);
  const toDisplayName = useRealmToDisplayName();

  // Auto select
  useEffect(() => {
    if (
      hasStorePickupOption &&
      Array.isArray(collectionOptions) &&
      (!returnSchedule || returnSchedule.auto || returnSchedule.date < date)
    ) {
      const firstNoSurcharge = collectionOptions?.find(({ option, enabled }) => option && enabled && !option.surcharge);

      if (firstNoSurcharge?.date && firstNoSurcharge.date !== returnSchedule?.date) {
        const { date, option } = firstNoSurcharge;
        updateCheckout({
          returnSchedule: {
            date,
            ...(option && { option }),
            auto: true,
          },
        });
      }
    }
  }, [hasStorePickupOption, collectionOptions, date, returnSchedule?.date]);

  const count = hasStorePickupOption
    ? [
        address
          ? `${
              returnSchedule.start && returnSchedule.end ? `${returnSchedule.start} ~ ${returnSchedule.end} ` : ""
            }${intl.formatMessage({ defaultMessage: "Home delivery" })}`
          : null,
        returnSchedule?.option?.surcharge ? intl.formatMessage({ defaultMessage: "Express surcharge" }) : null,
      ]
        .filter((data) => data)
        .join(" | ")
    : collectionPlace && collectionPlace !== businessCode
    ? intl.formatMessage(
        { defaultMessage: "at {location}" },
        {
          location: toDisplayName(collectionPlace) || collectionPlace,
        }
      )
    : collectionPlace === false
    ? intl.formatMessage({ defaultMessage: "Schedule later" })
    : returnSchedule
    ? intl.formatMessage({ defaultMessage: "Home delivery" })
    : null;

  return (
    <Badge
      offset={count ? [-20, 0] : null}
      count={count || undefined}
      dot={returnSchedule?.auto}
      status={returnSchedule?.auto ? "success" : returnSchedule?.option?.surcharge ? "warning" : "success"}
    >
      <Button
        icon={address || collectionPlace === false ? <CarOutlined /> : <CalendarOutlined />}
        type={selected ? "primary" : "default"}
        onClick={onSelect}
        danger={hasStorePickupOption && !returnSchedule}
      >
        {returnSchedule ? (
          <span>
            <DateDisplay monthFormat={"short"} date={returnSchedule.date} weekday="short" />
          </span>
        ) : collectionPlace === false ? (
          <FormattedMessage tagName={"span"} defaultMessage={"Home delivery"} />
        ) : hasStorePickupOption ? (
          <FormattedMessage tagName={"span"} defaultMessage={"Collection date"} />
        ) : (
          <FormattedMessage tagName={"span"} defaultMessage={"Self collection"} />
        )}
      </Button>
      <ScheduleDialog
        customerId={customer?.id}
        serviceId={service?.id}
        businessCode={businessCode}
        select={address ? returnSchedule : null}
        open={deliverySchedule}
        scheduleType={JOB_TYPE_DELIVERY}
        onCancel={() => setDeliverySchedule(false)}
        onSchedule={(returnSchedule) => {
          setDeliverySchedule(false);
          updateCheckout({ returnSchedule });
        }}
        onCancelSchedule={() => {
          setDeliverySchedule(false);
          updateCheckout({ returnSchedule: null });
        }}
      />
    </Badge>
  );
}
