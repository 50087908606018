import {
  useCheckoutEditOrder,
  useCheckoutService,
  useCheckoutServiceSetter,
  useRealmServices,
  useUserBusinessCode,
  useUserRealmId,
} from "@easybiz/component-shared";
import { formatMediaUrl } from "@easybiz/lib-shared-utils";
import { Title } from "@easybiz/web-admin";
import { Avatar, Button, Checkbox, List, message, Popover } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default () => {
  const businessCode = useUserBusinessCode();
  const services = useRealmServices(businessCode);
  const select = useCheckoutService();
  const setService = useCheckoutServiceSetter();
  const realmId = useUserRealmId();
  const eidtOrder = useCheckoutEditOrder();
  const [open, setVisible] = useState(false);
  const intl = useIntl();

  return (
    <Popover
      open={open}
      onOpenChange={(open) => {
        if (!eidtOrder) {
          setVisible(open);
        } else {
          message.info(
            intl.formatMessage({
              defaultMessage: "You can not switch business during order editing",
            })
          );
        }
      }}
      title={
        <Title>
          <FormattedMessage defaultMessage={"Switch business unit"} />
        </Title>
      }
      overlayStyle={{ width: 350 }}
      content={
        <List
          dataSource={services || []}
          renderItem={(service) => {
            return (
              <List.Item
                className="selectable"
                onClick={() => {
                  setService(service);
                  setVisible(false);
                }}
                extra={<Checkbox checked={select?.id === service.id} />}
              >
                <List.Item.Meta
                  avatar={
                    service.iconUrl || service.photoId ? (
                      <Avatar src={service.iconUrl || formatMediaUrl(service.photoId, realmId)} shape="square" />
                    ) : null
                  }
                  title={<Title ellipsis>{service.title}</Title>}
                  description={
                    service.pricing && (
                      <FormattedMessage
                        defaultMessage={"Price list: {version}"}
                        values={{
                          version: service.pricing,
                        }}
                      />
                    )
                  }
                />
              </List.Item>
            );
          }}
        />
      }
      trigger="click"
    >
      <Button size="large" disabled={Boolean(eidtOrder)}>
        <Avatar shape="square" src={select?.iconUrl || (select?.photoId && formatMediaUrl(select.photoId, realmId))} size={28}>
          {`${select?.title || ""}`.slice(0, 2)}
        </Avatar>
      </Button>
    </Popover>
  );
};
