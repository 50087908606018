export const PAGE_HOME = "home";
export const PAGE_LOGIN = "login";
export const PAGE_CREATE_ORDER = "create-order";
export const PAGE_EDIT_ORDER = "edit-order";
export const PAGE_PROCEDURE_PREVIEW = "procedure-preview";
export const PAGE_ORDER_DETAIL = "order-detail";
export const PAGE_MY_JOBS = "my-jobs";
export const PAGE_LIVE_CHAT = "live-chat";
export const PAGE_NOTIFICATIONS = "notifications";
export const PAGE_CUSTOM = "custom";
export const PAGE_MY_ACCOUNT = "my-account";
export const PAGE_NO_ACCESS = "no-access";
export const PAGE_NOT_FOUND = "not-found";
export const PAGE_SYSTEM_MENU = "system-menu";
export const PAGE_PHOTO_RECORD = "photo-record";
export const PAGE_CHECKPOINT_SCAN = "checkpoint-scan";
export const PAGE_PROCEDURE_STATUS = "procedure-status";
export const PAGE_MULTI_STEP_PROCEDURE = "multi-step-procedure";
export const PAGE_CUSTOMER_COUPON = "customer-coupon";
export const PAGE_ADJUSTMENT_OPTIONS = "adjustment-options";
export const PAGE_AGENT_LIST = "agent-list";
export const PAGE_NOTE = "note";
export const PAGE_ORDER_HISTORY = "order-history";
export const PAGE_LINE_ITEM_EDIT = "line-item-edit";
export const PAGE_BLUETOOTH_PRINTER_CONNECT = "bluetooth-printer-connect";

export const ORDER_DATA_RECEIPTS = "receipts";
export const ORDER_DATA_LABELS = "labels";
export const ORDER_DATA_PHOTOS = "photos";
export const ORDER_DATA_LINE_ITEMS = "items";
export const ORDER_DATA_PAYMENTS = "payments";
export const ORDER_DATA_FULFILLMENT = "fulfillment";
export const ORDER_DATA_PROGRESS = "progress";
export const ORDER_DATA_HISTORY = "history";
export const ORDER_DATA_CUSTOM = "custom";

export const LAYOUT_BOTTOM_TABS = "bottom_tabs";
export const LAYOUT_DRAWER = "drawer";
export const VIEW_TAB = "tab";
export const VIEW_LIST = "list";

export const COMPONENT_PRODUCT_GRID_WITH_CATEGORY = "product_grid_with_category";
export const COMPONENT_SERVICE_TYPE_OPTION = "service_type_option";

export const CONTENT_MODEL_EMAIL = "email";
export const CONTENT_MODEL_WHATSAPP = "whatsapp";
export const CONTENT_MODEL_PDF = "pdf";
export const CONTENT_MODEL_SPREADSHEET = "spreadsheet";
export const CONTENT_MODEL_RECEIPT = "receipt";
export const CONTENT_MODEL_LABEL = "label";
export const CONTENT_MODEL_IMAGE = "image";
export const CONTENT_MODEL_WEBSITE = "website";
export const CONTENT_MODEL_BANNER = "banner";
export const CONTENT_MODEL_POPUP = "popup";
export const CONTENT_MODEL_PUSH = "push";
export const CONTENT_MODEL_PAGE = "page";
export const CONTENT_MODEL_APP = "app";
export const CONTENT_MODEL_PRODUCT = "product";
export const CONTENT_MODEL_SERVICE_OPTION = "service_option";

export const STORAGE_RESOURCES_VERSION_DEFAULT = "default";

export const STORAGE_FOLDER_ORDER = "o";
export const STORAGE_FOLDER_CHAT = "c";
export const STORAGE_FOLDER_PERMANENT = "p";
export const STORAGE_FOLDER_SIGNATURE = "s";

export const STORAGE_ID_STANDARD = "s";
export const STORAGE_ID_PRO = "p";
export const STORAGE_ID_ENTERPRISE = "e";

// Signature
export const SIGNATURE_TYPE_PLACEMENT = "placement";
export const SIGNATURE_TYPE_COLLECTION = "collection";

export const ORIENTATION_PORTRAIT = "portrait";
export const ORIENTATION_LANDSCAPE = "landscape";

export const ROW_SET_DATE = "date";
export const ROW_SET_ORDER = "order";

// Sort
export const SORT_ASC = "asc";
export const SORT_DESC = "desc";

// Billing model
export const BILLING_MODEL_FIXED_AMOUNT = "fixed_amount";
export const BILLING_MODEL_FULL_SALES_ORDER_AMOUNT = "full_sales_order_amount";
export const BILLING_MODEL_PARTIAL_SALES_ORDER_AMOUNT = "partial_sales_order_amount";

// Auth Server actions
