import {
  LINE_ITEM_ID_MINIMUM_CHARGE,
  LINE_ITEM_ID_TOTAL_SERVICE,
  PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_INDEX,
  PLACEHOLDER_ORDER_LINE_ITEM_ID,
  PLACEHOLDER_ORDER_LINE_ITEM_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_PHOTO,
  PLACEHOLDER_ORDER_LINE_ITEM_QTY,
  PLACEHOLDER_ORDER_LINE_ITEM_TOTAL,
  TEMPLATE_CONTENT_TYPE_ITEM
} from "./constants/content.js";
import formatNumber from "./formatNumber.js";
import formatPhotoUrl from "./formatPhotoUrl.js";
import formatTemplateLineItem from "./formatTemplateLineItem.js";

export default function formatTemplateLineItems(
  { items, labels, currency, minimumOrder, minimumSurchargeAmount, serviceTypes, fixedQty, fixedAmount },
  realmId,
  dateFormat,
  currencyDisplay,
  placeholders,
  contentType
) {
  const lineItems = [];
  const emptyOrder = (items || []).length === 0;

  if (emptyOrder) {
    if (typeof fixedQty === "number" || typeof fixedAmount === "number") {
      let lineItem = {
        [PLACEHOLDER_ORDER_LINE_ITEM_ID]: LINE_ITEM_ID_TOTAL_SERVICE,
      };

      placeholders.forEach((placeholder) => {
        switch (placeholder) {
          case PLACEHOLDER_ORDER_LINE_ITEM_NAME:
            lineItem[placeholder] = "Total service";
            break;
          case PLACEHOLDER_ORDER_LINE_ITEM_QTY:
            lineItem[placeholder] = fixedQty;
            break;
          case PLACEHOLDER_ORDER_LINE_ITEM_TOTAL:
            lineItem[placeholder] = formatNumber(fixedAmount || 0, currency, true, currencyDisplay);
            break;
        }
      });

      lineItems.push(lineItem);
    } else {
      serviceTypes?.forEach((serviceType) => {
        let lineItem = {
          [PLACEHOLDER_ORDER_LINE_ITEM_ID]: serviceType.id || null,
        };

        placeholders.forEach((placeholder) => {
          switch (placeholder) {
            case PLACEHOLDER_ORDER_LINE_ITEM_NAME:
              lineItem[placeholder] = `${serviceType.title || ""}${
                typeof serviceType.minimumOrder === "number"
                  ? `(Minimum charge for ${serviceType.title || ""} is ${formatNumber(
                      serviceType.minimumOrder,
                      currency,
                      true,
                      currencyDisplay
                    )})`
                  : ""
              }`;
              break;
            case PLACEHOLDER_ORDER_LINE_ITEM_PHOTO:
              if (serviceType.photoId) {
                lineItem[placeholder] = {
                  url: formatPhotoUrl(serviceType.photoId),
                };
              }
              break;
            case PLACEHOLDER_ORDER_LINE_ITEM_TOTAL:
              lineItem[placeholder] = formatNumber(serviceType.minimumOrder, currency, true, currencyDisplay);
              break;
          }
        });

        lineItems.push(lineItem);
      });
    }
  } else {
    const isLabel = contentType === TEMPLATE_CONTENT_TYPE_ITEM;

    (isLabel
      ? labels
          ?.map((labelId, labelIndex) => {
            const item = items?.find(({ labelIndexes }) => labelIndexes?.includes(labelIndex + 1));
            if (item) {
              return {
                ...item,
                labelId,
                labelIndex: labelIndex + 1,
              };
            }
          })
          .filter(Boolean)
      : items
    )?.forEach((item) => {
      const lineItem = formatTemplateLineItem(item,
        { items, currency },
        realmId,
        dateFormat,
        currencyDisplay,
        placeholders);

      lineItems.push(lineItem);

      if (isLabel && Array.isArray(item.accessories)) {
        let accessoryIndex = 0;

        item.accessories.forEach(({ qty, title }) => {
          Array.from({ length: Number.isInteger(qty) ? qty : 0 }, (_, index) => {
            accessoryIndex++;

            lineItems.push({
              ...lineItem,
              [PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_INDEX]: accessoryIndex,
              [PLACEHOLDER_ORDER_LINE_ITEM_NAME]: `Accessory: ${title} x ${qty}${
                qty > 1 ? `(${index + 1}/${qty})` : ""
              }`,
              [PLACEHOLDER_ORDER_LINE_ITEM_ID]: null,
            });
          });
        });
      }
    });

    if (Array.isArray(serviceTypes)) {
      serviceTypes.forEach((serviceType) => {
        if (typeof serviceType.minCharge === "number" && serviceType.minCharge > 0) {
          lineItems.push({
            [PLACEHOLDER_ORDER_LINE_ITEM_ID]: serviceType.id || null,
            ...(placeholders.includes(PLACEHOLDER_ORDER_LINE_ITEM_NAME) && {
              [PLACEHOLDER_ORDER_LINE_ITEM_NAME]: `${serviceType.title || ""} difference to minimum${
                typeof serviceType.minimumOrder === "number"
                  ? ` (Minimum charge for ${serviceType.title} is ${formatNumber(
                      serviceType.minimumOrder,
                      currency,
                      true,
                      currencyDisplay
                    )})`
                  : ""
              }`,
            }),
            ...(placeholders.includes(PLACEHOLDER_ORDER_LINE_ITEM_TOTAL) && {
              [PLACEHOLDER_ORDER_LINE_ITEM_TOTAL]: formatNumber(serviceType.minCharge, currency, true, currencyDisplay),
            }),
          });
        }
      });
    }
  }

  if (minimumSurchargeAmount > 0) {
    lineItems.push({
      [PLACEHOLDER_ORDER_LINE_ITEM_ID]: LINE_ITEM_ID_MINIMUM_CHARGE,
      ...(placeholders.includes(PLACEHOLDER_ORDER_LINE_ITEM_NAME) && {
        [PLACEHOLDER_ORDER_LINE_ITEM_NAME]: `Difference to minimum (Order minimum charge is ${formatNumber(
          minimumOrder,
          currency,
          true,
          currencyDisplay
        )})`,
      }),
      ...(placeholders.includes(PLACEHOLDER_ORDER_LINE_ITEM_TOTAL) && {
        [PLACEHOLDER_ORDER_LINE_ITEM_TOTAL]: formatNumber(minimumSurchargeAmount, currency, true, currencyDisplay),
      }),
    });
  }

  return lineItems;
}
