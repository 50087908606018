import { EditOutlined } from "@ant-design/icons";
import { useOrderSalesReturn } from "@easybiz/api-order";
import { AmountDisplay } from "@easybiz/component";
import { WRITE_SALES_RETURN, PERMISSION_LEVEL_REQUIRES_APPROVAL } from "@easybiz/lib-shared-auth";
import { useAccessAction } from "@easybiz/component-shared";
import { Input, message, Space } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Dialog from "../Dialog";
import IconButton from "../IconButton";
import NumericUpdatePanel from "../NumericUpdatePanel";
import OrderPricingList from "../OrderPricingList";
import QtyInputPopover from "../QtyInputPopover";
import SalesReturnItemsSelectList from "../SalesReturnItemsSelectList";
import Title from "../Title";

export default ({ order, selectItems, open, onCancel }) => {
  const [creditNote, setCreditNote] = useState();
  const [amountVisible, setAmountVisible] = useState(false);
  const intl = useIntl();
  const haveReturnAmount = typeof creditNote?.lineTotal === "number";
  const [{ level }] = useAccessAction(WRITE_SALES_RETURN);
  const approval = level === PERMISSION_LEVEL_REQUIRES_APPROVAL;

  const { onSubmit, loading } = useOrderSalesReturn((success) => success && onCancel());

  useEffect(() => {
    if (open) {
      setCreditNote(null);
    }
  }, [open]);

  return (
    <Dialog
      disabled={!haveReturnAmount}
      title={
        <FormattedMessage
          tagName="span"
          defaultMessage="{orderNo} Sales return"
          values={{ orderNo: `#${order && order.get("orderNo")}` }}
        />
      }
      open={open}
      onCancel={onCancel}
      confirmLoading={loading}
      okText={<FormattedMessage tagName="span" defaultMessage="Submit sales return" />}
      onOk={() => {
        onSubmit({
          orderId: order.id,
          creditNote,
          approval,
        });
      }}
      okButtonProps={haveReturnAmount ? null : { disabled: true }}
    >
      {haveReturnAmount ? (
        <Space direction="vertical" className="full-width" size={"middle"}>
          {creditNote.pricing && (
            <OrderPricingList
              header={<FormattedMessage defaultMessage="Sales Return Items" />}
              pricing={creditNote.pricing}
              onSelect={() => setCreditNote({ ...creditNote, lineTotal: null })}
            />
          )}
          <Space>
            <Title>
              <FormattedMessage
                defaultMessage={"Sales return amount: {amount}"}
                values={{
                  amount: <AmountDisplay value={creditNote.lineTotal} />,
                }}
              />
            </Title>
            {creditNote.pricing ? (
              <QtyInputPopover
                open={amountVisible}
                onOpenChange={setAmountVisible}
                title={<FormattedMessage defaultMessage={"Total return amount"} />}
                placement={"right"}
                value={creditNote.lineTotal}
                onDone={(lineTotal) => {
                  if (typeof lineTotal !== "number") {
                    return message.info(
                      intl.formatMessage({
                        defaultMessage: "Total return amount must be a number",
                      })
                    );
                  }

                  setAmountVisible(false);
                  setCreditNote({ ...creditNote, lineTotal });
                }}
                isCurrency
              >
                <IconButton icon={<EditOutlined />} />
              </QtyInputPopover>
            ) : (
              <IconButton icon={<EditOutlined />} onClick={() => setCreditNote({ ...creditNote, lineTotal: null })} />
            )}
          </Space>
          <Input.TextArea
            placeholder={intl.formatMessage({
              defaultMessage: "Sales return note",
            })}
            value={creditNote?.description}
            onChange={(e) => setCreditNote({ ...creditNote, description: e.target.value })}
          />
        </Space>
      ) : selectItems ? (
        <SalesReturnItemsSelectList order={order} selects={creditNote?.pricing?.items} onCreditNote={setCreditNote} />
      ) : (
        <NumericUpdatePanel
          isCurrency
          prefix={<FormattedMessage defaultMessage={"Sales return"} />}
          onDone={(amount) => {
            setCreditNote({ ...creditNote, lineTotal: amount });
          }}
          buttonText={<FormattedMessage tagName="span" defaultMessage="OK" />}
        />
      )}
    </Dialog>
  );
};
