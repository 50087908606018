"use client";

import { useClientDate, useLicenseCloseOfDay } from "@easybiz/component-shared";

export default function usePOSIsDayClosed() {
  const date = useClientDate();
  const closedDate = useLicenseCloseOfDay();

  return closedDate === date;
}
