import { SALES_CHANNEL_TYPE_ONLINE } from "./constants/business.js";
import {
  PLACEHOLDER_CREDIT_BALANCE,
  PLACEHOLDER_DATA_SET_COMPANY,
  PLACEHOLDER_DATA_SET_CUSTOMER,
  PLACEHOLDER_DATA_SET_ORDER,
  PLACEHOLDER_DATA_SET_SALES_CHANNEL,
  PLACEHOLDER_ORDER_PAYNOW_IMAGE_URL,
  PLACEHOLDER_REWARD_POINTS_BALANCE,
  PLACEHOLDER_SALES_CHANNEL_DOMAIN,
  PLACEHOLDER_SIGNATURE,
} from "./constants/content.js";
import {
  JOB_STATUS_COLLECTED,
  JOB_STATUS_COMPLETE,
  JOB_STATUS_FAILED,
  JOB_STATUS_PENDING,
  JOB_STATUS_SCHEDULED,
  JOB_TYPE_DELIVERY,
  JOB_TYPE_PICKUP,
} from "./constants/logistic.js";
import firestoreExists from "./firestoreExists.js";
import firestoreGetDoc from "./firestoreGetDoc.js";
import firestoreGetDocs from "./firestoreGetDocs.js";
import generatePayNowUrl from "./generatePayNowUrl.js";
import retrieveDataSetsPlaceholders from "./retrieveDataSetsPlaceholders.js";

export default async function asyncPrepareTemplateData(
  template,
  initData,
  context,
  firestore,
  initPlaceholders,
  stripe
) {
  const { realmId, companys, collectionPoints } = context;
  const [dataSets, placeholders, placeholderParams] = retrieveDataSetsPlaceholders(template, initPlaceholders);

  const dataSet = {
    ...initData,
    ...(initData?.order?.collectionPlace && {
      collectionPlace: collectionPoints?.find((point) => point.name === initData.order.collectionPlace),
    }),
  };

  // Intermediary data
  const businessCode =
    dataSet.task?.businessCode ||
    dataSet.order?.business?.id ||
    dataSet.transaction?.businessCode ||
    initData?.businessCode;
  const companyId =
    initData?.companyId || (businessCode && companys?.find((company) => company.codes?.includes(businessCode))?.id);
  const customer = dataSet.task?.customer || dataSet.order?.customer || dataSet.transaction?.customer;
  const customerId = customer?.id || initData.customerId;

  if (dataSets.includes(PLACEHOLDER_DATA_SET_SALES_CHANNEL)) {
    if (dataSet.order?.agent) {
      // B2B order
      const agentDoc = await firestoreGetDoc(firestore, `realms/${realmId}/factory_agents/${dataSet.order.agent.id}`);
      dataSet.business = firestoreExists(agentDoc) ? { ...agentDoc.data(), id: agentDoc.id } : null;
    } else {
      // B2C order
      if (businessCode) {
        const businessDoc = await firestoreGetDoc(firestore, `realms/${realmId}/businesses/${businessCode}`);

        dataSet.business = firestoreExists(businessDoc) ? { ...businessDoc.data(), id: businessDoc.id } : null;

        // Check for online business under the same company
        if (
          dataSet.business?.companyId &&
          placeholders?.includes(PLACEHOLDER_SALES_CHANNEL_DOMAIN) &&
          dataSet.business?.type !== SALES_CHANNEL_TYPE_ONLINE
        ) {
          // For other business type, check if there is an online business under the same company
          const onlineBusiness = await firestoreGetDocs(firestore, `realms/${realmId}/businesses`, {
            wheres: [
              ["companyId", "==", dataSet.business.companyId],
              ["type", "==", SALES_CHANNEL_TYPE_ONLINE],
            ],
            limit: 1,
          });

          if (!onlineBusiness.empty) {
            dataSet.business.domain =
              onlineBusiness.docs[0].get("customDomain") || onlineBusiness.docs[0].get("domain");
          }
        }
      }
    }
  }

  if (companyId && dataSets.includes(PLACEHOLDER_DATA_SET_COMPANY)) {
    const companyDoc = await firestoreGetDoc(firestore, `realms/${realmId}/companys/${companyId}`);
    dataSet.company = firestoreExists(companyDoc) ? { ...companyDoc.data(), id: companyDoc.id } : null;
  }

  if (customerId && dataSets.includes(PLACEHOLDER_DATA_SET_CUSTOMER)) {
    const customerDoc = await firestoreGetDoc(firestore, `realms/${realmId}/customers/${customerId}`);

    if (firestoreExists(customerDoc) || customer) {
      dataSet.customer = { ...customer, ...customerDoc.data(), id: customerDoc.id };
    }
  }

  if (customerId && placeholders.includes(PLACEHOLDER_CREDIT_BALANCE)) {
    const creditWalletDoc = await firestoreGetDoc(firestore, `realms/${realmId}/wallets/${customerId}`);
    dataSet.creditWallet = creditWalletDoc.data();
  }

  if (customerId && placeholders.includes(PLACEHOLDER_REWARD_POINTS_BALANCE)) {
    const rewardPointsDoc = await firestoreGetDoc(firestore, `realms/${realmId}/memberships/${customerId}`);
    dataSet.rewardPoints = rewardPointsDoc.data();
  }

  if (
    !dataSet.collectionPlace &&
    dataSet.order?.collectionPlace &&
    placeholders.some((placeholder) => `${placeholder}`.startsWith(`${PLACEHOLDER_DATA_SET_ORDER}_pickup_store_`))
  ) {
    const collectionPlaceDoc = await firestoreGetDoc(
      firestore,
      `realms/${realmId}/businesses/${dataSet.order.collectionPlace}`
    );

    if (firestoreExists(collectionPlaceDoc)) {
      dataSet.collectionPlace = { ...collectionPlaceDoc.data(), id: collectionPlaceDoc.id };
    }
  }

  if (!dataSet.signature && dataSet.order && placeholders.includes(PLACEHOLDER_SIGNATURE)) {
    const signatureDoc = await firestoreGetDoc(
      firestore,
      `realms/${realmId}/orders/${dataSet.order.id}/signatures/placement`
    );
    dataSet.signature = signatureDoc.get("dataUrl");
  }

  if (
    !dataSet.pickupTask &&
    placeholders.some((dataKey) => `${dataKey}`.startsWith(`${PLACEHOLDER_DATA_SET_ORDER}_${JOB_TYPE_PICKUP}`))
  ) {
    if (dataSet.task?.type === JOB_TYPE_PICKUP) {
      dataSet.pickupTask = dataSet.task;
    } else if (dataSet.order) {
      const taskQuery = await firestoreGetDocs(firestore, `realms/${realmId}/tasks`, {
        wheres: [
          ["orderId", "==", dataSet.order.id],
          [
            "status",
            "in",
            [JOB_STATUS_PENDING, JOB_STATUS_FAILED, JOB_STATUS_SCHEDULED, JOB_STATUS_COMPLETE, JOB_STATUS_COLLECTED],
          ],
          ["type", "==", JOB_TYPE_PICKUP],
        ],
        limit: 1,
      });

      if (!taskQuery.empty) {
        dataSet.pickupTask = { ...taskQuery.docs[0].data(), id: taskQuery.docs[0].id };
      }
    }
  }

  if (
    !dataSet.deliveryTask &&
    placeholders.some((dataKey) => `${dataKey}`.startsWith(`${PLACEHOLDER_DATA_SET_ORDER}_${JOB_TYPE_DELIVERY}`))
  ) {
    if (dataSet.task?.type === JOB_TYPE_DELIVERY) {
      dataSet.deliveryTask = dataSet.task;
    } else if (dataSet.order) {
      const taskQuery = await firestoreGetDocs(firestore, `realms/${realmId}/tasks`, {
        wheres: [
          ["orderId", "==", dataSet.order.id],
          [
            "status",
            "in",
            [JOB_STATUS_PENDING, JOB_STATUS_FAILED, JOB_STATUS_SCHEDULED, JOB_STATUS_COMPLETE, JOB_STATUS_COLLECTED],
          ],
          ["type", "==", JOB_TYPE_DELIVERY],
        ],
        limit: 1,
      });

      if (!taskQuery.empty) {
        dataSet.deliveryTask = { ...taskQuery.docs[0].data(), id: taskQuery.docs[0].id };
      }
    }
  }

  if (
    stripe &&
    dataSet.order &&
    placeholders.includes(PLACEHOLDER_ORDER_PAYNOW_IMAGE_URL) &&
    dataSet.company?.stripeAccount
  ) {
    // Generate Stripe QRCode (Server only)
    dataSet.payNowUrl = generatePayNowUrl(
      context,
      dataSet.order.id,
      dataSet.company.stripeAccount.id,
      stripe,
      firestore.getFirestore
    );
  }

  return {
    placeholders,
    placeholderParams,
    dataSet,
  };
}
