"use client";

import { OWNER_LICENSE_ID } from "@easybiz/lib-shared-auth";
import { PLATFORM_TEMPLATE_REALM_ID } from "@easybiz/lib-shared-utils";
import { createContext, useContext, useMemo, useState } from "react";
import { useRealmContext } from "./RealmContext";
import { useUser } from "./UserContext";

const AccessContext = createContext();

export function AccessProvider({ children, accessReady }) {
  const user = useUser();
  const realm = useRealmContext();
  const [mockUser, setMockUser] = useState();
  const [currentApp, setCurrentApp] = useState();

  const context = useMemo(() => {
    const onlineLicenses = realm?.onlineLicences;
    const storeLicenses = realm?.storeLicences;
    const plantLicense = realm?.plantLicence;
    const { clientAccess, limitBusinesses, procedures, limitAgents, storePlants, specialists, licenseId } =
      mockUser || user || {};
    const businessLimited = Array.isArray(limitBusinesses) && limitBusinesses.length > 0;

    return {
      accessReady,
      licenseId,
      isOwner: licenseId === OWNER_LICENSE_ID,
      currentApp: clientAccess
        ? (clientAccess.apps || []).some((item) => item.id === currentApp?.id)
          ? currentApp
          : clientAccess.apps?.[0] || null
        : undefined,
      apps: clientAccess?.apps,
      limitBusinesses,
      limitProcedures: procedures,
      limitAgents,
      storePlants,
      shiftManagement: user?.shiftManagement,
      agentId: Array.isArray(specialists) ? user?.uid : null,
      ...(realm && {
        onlineLicenses: businessLimited
          ? (onlineLicenses || []).filter((code) => limitBusinesses.indexOf(code) >= 0)
          : onlineLicenses || [],
      }),
      ...(realm && {
        storeLicenses: businessLimited
          ? (storeLicenses || []).filter((code) => limitBusinesses.indexOf(code) >= 0)
          : storeLicenses || [],
      }),
      plantLicense,
      mockUser,
      isTemplateEnv: user?.realmId === PLATFORM_TEMPLATE_REALM_ID,
      setMockUser,
      setCurrentApp,
    };
  }, [user, realm, mockUser, currentApp, accessReady]);

  return <AccessContext.Provider value={context}>{children}</AccessContext.Provider>;
}

function useAccess(accessMap, defaultGranted, ...keys) {
  const { isOwner } = useContext(AccessContext);

  return keys.map((key) => (isOwner ? {} : accessMap?.[key] || (defaultGranted ? {} : null)));
}

export const useAccessReady = () => useContext(AccessContext).accessReady;
export const useAccessClient = (...scopes) => {
  const user = useUser();
  return useAccess(user?.clientAccess, false, ...scopes);
};
export const useAccessData = (...dataTypes) => {
  const user = useUser();
  // Default have full data access
  return useAccess(user?.dataAccess, true, ...dataTypes);
};
export const useAccessAction = (...actions) => {
  const user = useUser();
  // Default have full action access
  return useAccess(user?.actionAccess, true, ...actions);
};
export const useAccessInOwner = () => useContext(AccessContext).isOwner;
export const useAccessIsTemplateEnv = () => useContext(AccessContext).isTemplateEnv;
export const useAccessOnlineIds = () => useContext(AccessContext).onlineLicenses;
export const useAccessStoreIds = () => useContext(AccessContext).storeLicenses;
export const useAccessB2B = () => useContext(AccessContext).plantLicense;
export const useAccessLimitBusinessIds = () => useContext(AccessContext).limitBusinesses;
export const useAccessLimitProcedures = () => useContext(AccessContext).limitProcedures;
export const useAccessLimitAgents = () => useContext(AccessContext).limitAgents;
export const useAccessStorePlants = () => useContext(AccessContext).storePlants;
export const useAccessShiftManagement = () => useContext(AccessContext).shiftManagement;
export const useAccessMockUser = () => useContext(AccessContext).mockUser;
export const useAccessMockUserSetter = () => useContext(AccessContext).setMockUser;
export const useAccessCurrentApp = () => useContext(AccessContext).currentApp;
export const useAccessPages = () => useContext(AccessContext).currentApp?.linkedPages;
export const useAccessPage = (typeOrId) => {
  const { currentApp } = useContext(AccessContext);
  return currentApp?.linkedPages?.find((page) => page.type === typeOrId || page.id === typeOrId);
};
export const useAccessCurrentAppSetter = () => useContext(AccessContext).setCurrentApp;
export const useAccessApps = () => useContext(AccessContext).apps;
// TODO: IMPROVE
export const useAccessSelfAgentId = () => useContext(AccessContext).agentId;
