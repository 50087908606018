"use client";

import { useAccessAction, useClientType } from "@easybiz/component-shared";
import { WRITE_CANCEL_PAYMENT } from "@easybiz/lib-shared-auth";
import { CLIENT_POS, formatPrice, isDayLimited } from "@easybiz/utils";
import dayjs from "dayjs";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import AmountDisplay from "../AmountDisplay";
import DateDisplay from "../DateDisplay";
import PaymentMethodDisplay from "../PaymentMethodDisplay";

function useOrderPaymentStatusList(order, summaryMode) {
  const [posCancel] = useAccessAction(WRITE_CANCEL_PAYMENT);
  const client = useClientType();
  const { netSales, totalWaived, totalPaid, voided, pricingTBD, paymentIntent } = order?.data() || {};
  const overPaidAmount = formatPrice((totalPaid || 0) - Math.max(0, (netSales || 0) - (totalWaived || 0)));
  const intl = useIntl();

  return useMemo(() => {
    const { netSales, payments, totalWaived, totalBalance, paid } = order?.data() || {};

    return {
      dataSource: order && [
        ...(summaryMode
          ? []
          : [
              {
                title: <FormattedMessage id="payable.amount" defaultMessage="Payable Amount" />,
                strong: true,
                amount: <AmountDisplay value={formatPrice(netSales - totalWaived)} />,
                ...(pricingTBD && {
                  tag: <FormattedMessage id="pending.confirm" defaultMessage={"Pending confirm"} />,
                  tagType: "info",
                }),
              },
            ]),
        ...(payments || [])
          .filter((payment) => !payment.pt)
          .map((payment) => {
            const { v, t, o, m, n, points, backDate, deposit } = payment;
            const isRefund = v < 0;
            const refund = payments.find((other) => other.pt === t);
            const refunded = Boolean(refund);
            const limited = isRefund
              ? true
              : client === CLIENT_POS && isDayLimited(dayjs.unix(t).format("YYYY-MM-DD"), posCancel);

            return {
              title: isRefund ? (
                <FormattedMessage
                  id="refund.by.x"
                  defaultMessage={"Refund by {option}"}
                  values={{ option: <PaymentMethodDisplay option={m} /> }}
                />
              ) : (
                <FormattedMessage
                  id="paid.by.x"
                  defaultMessage={"Paid by {option}"}
                  values={{
                    option: (
                      <>
                        <PaymentMethodDisplay option={m} />
                        {Number.isInteger(points)
                          ? ` (${intl.formatMessage(
                              {
                                id: "x.points.used",
                                defaultMessage: "{number} points used",
                              },
                              { number: points }
                            )})`
                          : null}
                      </>
                    ),
                  }}
                />
              ),
              description: (
                <>
                  <DateDisplay timestamp={t} />
                  {` ${o}${n ? ` (${n})` : ""}`}
                </>
              ),
              ...((deposit || backDate) && {
                infos: [
                  ...(deposit ? [<FormattedMessage id="deposit.payment" defaultMessage={"Deposit payment"} />] : []),
                  ...(backDate
                    ? [
                        <FormattedMessage
                          id="back.date.payment.on.x"
                          defaultMessage={"Back date payment on {date}"}
                          values={{
                            date: <DateDisplay date={backDate} />,
                          }}
                        />,
                      ]
                    : []),
                ],
              }),
              ...(refunded && {
                alerts: [
                  <>
                    <DateDisplay timestamp={refund.t} /> {refund.o} {refund.n ? `(${refund.n})` : ""}
                  </>,
                ],
              }),
              color: refunded ? `#d1d5db` : isRefund ? null : `#15803d`,
              refunded,
              amount: <AmountDisplay value={-v} accounting />,
              ...(refunded
                ? {
                    tag: <FormattedMessage id="refunded" defaultMessage={"Refunded"} />,
                  }
                : isRefund || limited
                ? null
                : {
                    refundablePayment: payment,
                  }),
            };
          }),
        ...(totalBalance > 0 && paymentIntent
          ? [
              {
                title: (
                  <FormattedMessage
                    id="customer.intent.pay.by.x"
                    defaultMessage={"Customer intent pay by {paymentMethod}"}
                    values={{
                      paymentMethod: <PaymentMethodDisplay option={paymentIntent.option} />,
                    }}
                  />
                ),
                tag: <FormattedMessage id="pending" defaultMessage={"Pending"} />,
                tagType: "info",
                paymentIntent,
              },
            ]
          : []),
        ...(overPaidAmount > 0
          ? [
              {
                title: <FormattedMessage id="amount.overpaid" defaultMessage={"Amount Overpaid"} />,
                amount: <AmountDisplay value={overPaidAmount} />,
                color: `#1d4ed8`,
                strong: true,
              },
            ]
          : []),
        ...(summaryMode && (voided || paid || payments?.length === 0)
          ? []
          : [
              {
                title: <FormattedMessage id="total.outstanding" defaultMessage={"Total Outstanding"} />,
                amount: voided ? (
                  <FormattedMessage id="voided" defaultMessage={"Voided"} />
                ) : paid ? (
                  <FormattedMessage id="paid" defaultMessage={"Paid"} />
                ) : (
                  <AmountDisplay value={totalBalance} />
                ),
                strong: true,
                color: voided ? `#d1d5db` : paid ? `#15803d` : `#b91c1c`,
              },
            ]),
      ],
    };
  }, [order, client, posCancel, summaryMode]);
}

export default useOrderPaymentStatusList;
