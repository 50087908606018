"use client";

import { useClientDate, useClientDateSetter } from "@easybiz/component-shared";
import dayjs from "dayjs";
import { useEffect } from "react";

export default function ClientDateMonitor() {
  const date = useClientDate();
  const setDate = useClientDateSetter();

  useEffect(() => {
    const handler = setInterval(() => {
      if (dayjs().format("YYYY-MM-DD") !== date) {
        setDate(dayjs().format("YYYY-MM-DD"));
      }
    }, 10 * 60 * 1000);

    return () => clearInterval(handler);
  }, [date]);
}
