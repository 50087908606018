import {
  DATA_FIELD_TYPE_AMOUNT,
  DATA_FIELD_TYPE_DATE,
  DATA_FIELD_TYPE_MULTI_SELECT,
  DATA_FIELD_TYPE_PHONE_NUMBER,
} from "@easybiz/lib-shared-bpm";
import { parseResourceId } from "@easybiz/lib-shared-utils";
import formatDate from "./formatDate.js";
import formatNumber from "./formatNumber.js";
import formatString from "./formatString.js";

export default function formatCustomDataField(dataKey, dataSource, { dateFormat, currency, currencyDisplay }) {
  const data = dataSource?.[dataKey];

  if (data) {
    const [dataType] = parseResourceId(dataKey);

    switch (dataType) {
      case DATA_FIELD_TYPE_PHONE_NUMBER:
        return data.intl;
      case DATA_FIELD_TYPE_DATE:
        return formatDate(data, dateFormat);
      case DATA_FIELD_TYPE_MULTI_SELECT:
        return Array.isArray(data) ? data.map((item) => formatString(item)).join(", ") : null;
      case DATA_FIELD_TYPE_AMOUNT:
        return formatNumber(data, currency, true, currencyDisplay);
      default: {
        if (typeof data === "string" || typeof data === "number") {
          return data;
        } else if (data?.intl) {
          return data.intl;
        }
      }
    }
  }
}
