"use client";

import { useClientRegisterDevice, useRealmContext, useRealmServices, useUserRealmId } from "@easybiz/component-shared";
import { PRINTER_TYPE_PACKAGE_LABEL } from "@easybiz/lib-shared-bpm";
import {
  asyncPrepareTemplateData,
  formatTemplateRenderParams,
  formatTSPLCommands,
  PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_INDEX,
  PLACEHOLDER_ORDER_LINE_ITEM_ID,
  PLACEHOLDER_ORDER_LINE_ITEM_PCS_INDEX,
  PLACEHOLDER_ORDER_LINE_ITEMS,
  PLACEHOLDER_ORDER_PACKAGE_ID,
  PLACEHOLDER_ORDER_PACKAGES,
  TEMPLATE_CATEGORY_LABEL,
} from "@easybiz/utils";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

export default function useOrderLabelDisplay(firestore, order, printerType, configures) {
  const { createImageData, stringCommand, previewSettings } = configures || {};
  const { onSnapshot, doc, getFirestore } = firestore;
  const realmId = useUserRealmId();
  const context = useRealmContext();
  const services = useRealmServices();
  const service = services?.find(
    (service) => service.id === (typeof order?.get === "function" ? order?.get("service.id") : order?.service?.id)
  );

  const agentId = typeof order?.get === "function" ? order?.get("agent.id") : order?.agent?.id;
  const intl = useIntl();
  const device = useClientRegisterDevice();
  const printer = device?.get(`${printerType}Printer`);
  const [agentSetting, setAgentSetting] = useState();
  const [labelData, setLabelData] = useState(undefined);
  const settings = agentId ? agentSetting : service;
  const isPackage = printerType === PRINTER_TYPE_PACKAGE_LABEL;
  const templateId = settings?.[isPackage ? "packageLabelId" : "itemLabelId"];

  // For B2B agent, load settings from agent document
  useEffect(() => {
    if (realmId && agentId) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}/factory_agents/${agentId}`), (snapshot) => {
        setAgentSetting(snapshot.data() || {});
      });
    }
  }, [realmId, agentId]);

  useEffect(() => {
    setLabelData(undefined);

    if (realmId && templateId) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/templates/${TEMPLATE_CATEGORY_LABEL}/published/${templateId}`),
        (snapshot) => {
          const template = snapshot.get("data");

          if (template) {
            asyncPrepareTemplateData(
              template,
              {
                order: typeof order?.data === "function" ? { ...order.data(), id: order.id } : order,
              },
              context,
              firestore,
              [isPackage ? PLACEHOLDER_ORDER_PACKAGE_ID : PLACEHOLDER_ORDER_LINE_ITEM_ID]
            ).then((data) => setLabelData({ ...data, template }));
          }
        }
      );
    }
  }, [realmId, templateId, isPackage]);

  // null means template display not enabled
  return useMemo(() => {
    const renderParams =
      labelData &&
      formatTemplateRenderParams(
        order
          ? {
              ...labelData,
              dataSet: {
                ...labelData.dataSet,
                order: typeof order?.data === "function" ? { ...order.data(), id: order.id } : order,
              },
            }
          : labelData,
        { ...labelData.template.content, category: TEMPLATE_CATEGORY_LABEL },
        context
      );

    return {
      loading: !labelData || !settings,
      template: labelData?.template,
      renderParams,
      labels: (renderParams?.[isPackage ? PLACEHOLDER_ORDER_PACKAGES : PLACEHOLDER_ORDER_LINE_ITEMS] || [])
        .filter((item) => (isPackage ? true : item[PLACEHOLDER_ORDER_LINE_ITEM_PCS_INDEX]))
        .map((item) => {
          const [command, positions] = formatTSPLCommands(
            labelData.template,
            { ...renderParams, ...item },
            previewSettings || printer,
            intl,
            createImageData,
            stringCommand
          );

          return {
            ...item,
            command,
            positions,
            key: isPackage
              ? item[PLACEHOLDER_ORDER_PACKAGE_ID]
              : `${item[PLACEHOLDER_ORDER_LINE_ITEM_ID] || ""}${
                  item[PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_INDEX] || ""
                }`,
          };
        }),
    };
  }, [labelData, order, settings, templateId]);
}
